.App {
  min-height: 100vh;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.graph-no-data {
  color: black;
  background-color: #ccc;
  padding: 10px 0;
}

.date-range {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0 10px;
}
